import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './menu.css';

const DropdownMenu = ({ onOptionClick }) => {
    const location = useLocation();

    return (
        <div className="dropdown-menu show">
            <NavLink
                to="/"
                className={`option ${location.pathname === '/' ? 'active' : ''}`}
                onClick={onOptionClick}
            >
                INICIO
            </NavLink>
            <NavLink
                to="/servicios"
                className={`option ${location.pathname === '/servicios' ? 'active' : ''}`}
                onClick={onOptionClick}
            >
                SERVICIOS
            </NavLink>
            <NavLink
                to="/sobre-nosotros"
                className={`option ${location.pathname === '/sobre-nosotros' ? 'active' : ''}`}
                onClick={onOptionClick}
            >
                SOBRE NOSOTROS
            </NavLink>
            <NavLink
                to="/especialistas"
                className={`option ${location.pathname === '/especialistas' ? 'active' : ''}`}
                onClick={onOptionClick}
            >
                ESPECIALISTAS
            </NavLink>
            <NavLink
                to="/contacto"
                className={`option ${location.pathname === '/contacto' ? 'active' : ''}`}
                onClick={onOptionClick}
            >
                CONTACTO
            </NavLink>
        </div>
    );
}

export default DropdownMenu;