import React, { useState, useCallback } from 'react';
import Valoracion from '../valoracion/valoracion';
import './valoracion-mov.css';

const reviews = [
    { text: "Gracias por vuestra profesionalidad y el trato tan humano que siempre tenéis con los clientes. Visitar esta clínica siempre es una experiencia muy positiva y los resultados son los esperados. La recomiendo a todo el mundo.", userName: "montse lopez aguilera" },
    { text: "Mi experiencia en Dentsana ha sido siempre fantástica. Llevo años acudiendo a la consulta y el trato es excelente, además de la profesionalidad y honestidad de todo su personal. ¡Gracias por todo!.", userName: "Manel Franquet" },
    { text: "Mi Experiencia es de 10. Todos son grandes profesionales el dentista,Paqui y Monica son un encanto,como personas y profesionales Llevo con ellos más de 20 años,eso quiere decir mucho.Lo recomiendo.", userName: "Manuela Molinos Poveda" },
    { text: "Estoy encantada,como trabajan y el trato tan cercano que ofrecen, te hacen sentir una tranquilidad y eso que a mi que me toquen la boca siempre me a dado miedito la recomiendo 100 × 100", userName: "Vanessa Belmonte Rueda" },
    { text: "Mi marido y yo hace poco que les conocemos y menuda suerte! Trato magnífico. Atención esplendida y honestidad. TODO el personal súper amable y atento. Sin duda se va a convertir en nuestro centro de referencia.", userName: "Mariripoll Ripoll" },
];

const ValoracionMov = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fadeClass, setFadeClass] = useState('fade-in');
    const [hidden, setHidden] = useState(false);

    const nextSlide = useCallback(() => {
        if (fadeClass === 'fade-out') return;
        setFadeClass('fade-out');
        setHidden(true);
    }, [fadeClass]);

    const prevSlide = () => {
        if (fadeClass === 'fade-out') return;
        setFadeClass('fade-out');
        setHidden(true);
    };

    const handleAnimationEnd = () => {
        if (fadeClass === 'fade-out') {
            setCurrentIndex((prevIndex) =>
                prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
            );
            setFadeClass('fade-in');
        }
        setHidden(false);
    };

    return (
        <div className="carousel-2">
            <button className="carousel-control-2 carousel-control-prev-2" onClick={prevSlide}>
                <img src="https://api.iconify.design/prime:angle-left.svg?color=rgb(198, 25, 128)" alt="Previous" />
            </button>

            <div
                className={`carousel-inner-2 ${fadeClass} ${hidden ? 'hidden' : ''}`}
                onAnimationEnd={handleAnimationEnd}
            >
                <Valoracion
                    key={currentIndex}
                    text={reviews[currentIndex].text}
                    userName={reviews[currentIndex].userName}
                />
            </div>

            <button className="carousel-control-2 carousel-control-next-2" onClick={nextSlide}>
                <img src="https://api.iconify.design/prime:angle-right.svg?color=rgb(198, 25, 128)" alt="Next" />
            </button>
        </div>
    );
};

export default ValoracionMov;
