import React, { useState, useEffect, useCallback } from 'react';
import './valoraciones.css';
import Valoracion from '../valoracion/valoracion';

const reviews = [
    { text: "Gracias por vuestra profesionalidad y el trato tan humano que siempre tenéis con los clientes. Visitar esta clínica siempre es una experiencia muy positiva y los resultados son los esperados. La recomiendo a todo el mundo.", userName: "montse lopez aguilera" },
    { text: "Es sin duda la mejor clínica de Martorell , hace años que voy a hacer el mantenimiento y todas las veces genial . Paqui , Monica y todo el equipo son un encanto y te asesoran y ayudan en todo para que estés tranquilo y a gusto . 100 x 100 recomendable", userName: "Sebas Harley" },
    { text: "Recomendable al 100%, llevamos casi 20 años yendo mi mujer y yo y mis hijos desde que nacieron, jijiji. Son súper profesionales, amables, se adaptan a tus necesidades y calidad precio para mi lo mejor de martorell. Lo he recomendado a muchas amistades y ahora solo van hay.", userName: "Juan A" },
    { text: "Mi experiencia en Dentsana ha sido siempre fantástica. Llevo años acudiendo a la consulta y el trato es excelente, además de la profesionalidad y honestidad de todo su personal. ¡Gracias por todo!.", userName: "Manel Franquet" },
    { text: "Mi Experiencia es de 10. Todos son grandes profesionales el dentista,Paqui y Monica son un encanto,como personas y profesionales Llevo con ellos más de 20 años,eso quiere decir mucho.Lo recomiendo.", userName: "Manuela Molinos Poveda" },
    { text: "Estoy encantada,como trabajan y el trato tan cercano que ofrecen, te hacen sentir una tranquilidad y eso que a mi que me toquen la boca siempre me a dado miedito la recomiendo 100 × 100", userName: "Vanessa Belmonte Rueda" },
    { text: "Mi marido y yo hace poco que les conocemos y menuda suerte! Trato magnífico. Atención esplendida y honestidad. TODO el personal súper amable y atento. Sin duda se va a convertir en nuestro centro de referencia.", userName: "Mariripoll Ripoll" },
    { text: "Sitio recomendable,buenos profesionales todos.Paqui y Mónica son estupendas,siempre intentando en ayudarte y buscar soluciones,buenas profesionales,llevamos toda la familia más de 20 años llendo y seguiremos siendo clientes,mis felicitaciones por este gran equipo", userName: "Pedro Frutos Cerezo" }
];

const Valoraciones = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = useCallback(() => {
        setCurrentIndex((prevIndex) =>
            prevIndex === reviews.length - 3 ? 0 : prevIndex + 1
        );
    }, []);

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? reviews.length - 3 : prevIndex - 1
        );
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 10000);
        return () => clearInterval(interval);
    }, [nextSlide]);

    return (
        <div className="carousel">
            <button className="carousel-control prev" onClick={prevSlide}>
                <img 
                    src="https://api.iconify.design/prime:angle-left.svg?color=rgb(198, 25, 128)" 
                    alt="prev" 
                    className="arrow-icon"
                />
            </button>
            <div className="carousel-container">
                <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 33.33}%)` }}>
                    {reviews.map((review, index) => (
                        <Valoracion
                            key={index}
                            text={review.text}
                            userName={review.userName}
                        />
                    ))}
                </div>
            </div>
            <button className="carousel-control next" onClick={nextSlide}>
                <img 
                    src="https://api.iconify.design/prime:angle-right.svg?color=rgb(198, 25, 128)" 
                    alt="next"
                    className="arrow-icon"
                />
            </button>
        </div>
    );
};

export default Valoraciones;