import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './header.css';
import logo from '../../assets/logo.png';
import DropdownMenu from '../../components/menu/menu';

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    return (
        <div className="Inicio-2">
            <div className="imageContainer-2">
                <div className="header-2">
                    <NavLink to="/"><img src={logo} alt="Logo" /></NavLink>
                    <div className='options-2'>
                        <ul className={`menu-options-2 ${menuVisible ? 'show-2' : ''}`}>
                            <li>
                                <NavLink 
                                    exact to="/"
                                    className={location.pathname === '/' ? 'active-2' : ''}>
                                    INICIO
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/servicios"
                                    className={location.pathname === '/servicios' ? 'active-2' : ''}>
                                    SERVICIOS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/sobre-nosotros"
                                    className={location.pathname === '/sobre-nosotros' ? 'active-2' : ''}>
                                    SOBRE NOSOTROS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/especialistas"
                                    className={location.pathname === '/especialistas' ? 'active-2' : ''}>
                                    ESPECIALISTAS
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/contacto"
                                    className={location.pathname === '/contacto' ? 'active-2' : ''}>
                                    CONTACTO
                                </NavLink>
                            </li>
                        </ul>

                        <button className="menu-icon-button-2" onClick={toggleMenu}>
                            <img src="https://api.iconify.design/heroicons-solid:menu.svg?color=rgb(198, 25, 128)" alt="Menu" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
