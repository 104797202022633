import React from 'react';
import './footer.css';
import Logo from '../../assets/logo.png';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-logo'>
                <img src={Logo} alt='Logo' className='footer-logo-image' />
            </div>
            <div className='footer-links'>
                <a href='/aviso-legal' className='footer-link'>Aviso Legal</a>
                <a href='/politica-privacidad' className='footer-link'>Política de Privacidad</a>
                <a href='/politica-cookies' className='footer-link'>Política de Cookies</a>
            </div>
            <div className='footer-copy'>
                <p>Dentsana Martorell, S.L. ©</p>
            </div>
        </footer>
    );
};

export default Footer;
