import React, { useState, useEffect, useRef } from 'react';
import Personal from '../personal/personal';
import './equipo.css';

const Equipo = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const equipoRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const images = [
        {
            id: 'personal5',
            nombre: 'Maria',
            apellidos: 'Seminario ',
            descripcion:'Cirujana, implantologa y generalista. Experta en intervenciones quirúrgicas.',
        },
        {
            id: 'personal6',
            nombre: 'Guillem',
            apellidos: 'Bernal',
            descripcion: 'Cirujano, implantologo y generalista. Experto en intervenciones quirúrgicas.',
        },
        {
            id: 'personal2',
            nombre: 'Anabel',
            apellidos: 'Montalvillo',
            descripcion: 'Ortodoncista. Encargada de diagnosticar, prevenir y corregir irregularidades dentales.',
        },
        {
            id: 'personal4',
            nombre: 'Cristina',
            apellidos: 'Sala ',
            descripcion: 'Responsable médico, generalista y estética. Especialista en procedimientos estéticos.',
        },
        {
            id: 'personal3',
            nombre: 'Yaiza',
            apellidos: 'Álvarez',
            descripcion: 'Higienista dental. Experta  en prevención y el tratamiento de las enfermedades dentales.',
        },
        {
            id: 'personal7',
            nombre: 'Mónica',
            apellidos: 'Queralt',
            descripcion: 'Administradora de clínica. Asiste al dentista en procedimientos, prepara el equipo y materiales.',
        },
        {
            id: 'personal1',
            nombre: 'Paqui',
            apellidos: 'Sánchez',
            descripcion: 'Administradora de clínica. Asiste al dentista en procedimientos, prepara el equipo y materiales.',
        },
    ];

    const handleLeftClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleRightClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const visibleImages = [
        images[(currentIndex + images.length - 2) % images.length],
        images[(currentIndex + images.length - 1) % images.length],
        images[currentIndex],
        images[(currentIndex + 1) % images.length],
        images[(currentIndex + 2) % images.length],
        images[(currentIndex + 3) % images.length],
        images[(currentIndex + 4) % images.length],
    ];


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.disconnect();
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (equipoRef.current) {
            observer.observe(equipoRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <div
            className={`container-team ${isVisible ? 'visible' : ''}`}
            ref={equipoRef}
        >
            <button className="arrow-button left-arrow" onClick={handleLeftClick}>
                <img src="https://api.iconify.design/prime:angle-right.svg?color=rgb(198, 25, 128)" alt="Left Arrow" style={{ transform: 'rotate(180deg)' }} />
            </button>
            <div className="equipo-container">
                {visibleImages.map((persona, index) => (
                    <div
                        key={index}
                        className={`img-personal ${index === 2 ? 'active' : ''}`}
                    >
                        <Personal value={persona.id} />
                        {index === 2 && (
                            <div className="text-overlay">
                                <div className='name-prof'>
                                    <h1>{persona.nombre}</h1>
                                    <h2>{persona.apellidos}</h2>
                                </div>
                                <p>{persona.descripcion}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <button className="arrow-button right-arrow" onClick={handleRightClick}>
                <img src="https://api.iconify.design/prime:angle-left.svg?color=rgb(198, 25, 128)" alt="Right Arrow" style={{ transform: 'rotate(180deg)' }} />
            </button>
        </div>
    );
};

export default Equipo;