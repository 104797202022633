import React, { useEffect, useState } from 'react';
import './servicio-text.css';

const ServicioText = React.forwardRef(({ title, content, contentShort, imageSrc, className }, ref) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 550);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 550);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getContentToDisplay = () => {
        return isSmallScreen ? contentShort : content;
    };

    return (
        <div ref={ref} className={`text-service-cont ${className}`}>
            <div className="content-serv">
                <div className="text-title">
                    <div className="titulo-serv-text">
                        <h2>{title}</h2>
                    </div>
                    <div className="parrafos-serv">
                        {getContentToDisplay().map((para, index) => (
                            <div key={index} className="parrafo-serv">
                                {para.title ? (
                                    <p className="parrafo-text">
                                        <strong>{para.title}:</strong>{' '}
                                        <span dangerouslySetInnerHTML={{ __html: para.text }} />
                                    </p>
                                ) : (
                                    <p className="parrafo-text" dangerouslySetInnerHTML={{ __html: para.text }} />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                {imageSrc && (
                    <div className="img-serv">
                        <img src={imageSrc} alt={title} />
                    </div>
                )}
            </div>
        </div>
    );
});

export default ServicioText;