import React from 'react';
import './especialistas.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import personal1 from '../../assets/personal-1.jpg';
import personal2 from '../../assets/personal-2.jpg';
import personal3 from '../../assets/personal-3.jpg';
import personal4 from '../../assets/personal-4.jpg';
import personal5 from '../../assets/personal-5.jpg';
import personal6 from '../../assets/personal-6.png';
import personal7 from '../../assets/personal-7.png';
import useSEO from '../../hooks/useSEO';

const Especialistas = () => {
    const especialistas = [
        { img: personal2, nombre: "Anabel Montalvillo", especialidad: "Ortodoncista", hoverText: "Especialista en el diagnóstico, prevención y tratamiento de las irregularidades dentales y faciales. Su rol principal es diseñar y aplicar tratamientos de ortodoncia para corregir problemas de alineación y mordida, utilizando aparatos como brackets y alineadores." },
        { img: personal4, nombre: "Cristina Sala", especialidad: "Responsable médico, generalista y estética", hoverText: "Coordina y supervisa las operaciones médicas de la clínica, asegurando que todos los procedimientos se realicen con los más altos estándares de calidad. Además de ofrecer tratamientos dentales generales, se especializa en procedimientos de estética dental, como blanqueamientos y restauraciones estéticas." },
        { img: personal5, nombre: "Maria Seminario", especialidad: "Cirujana, implantóloga y generalista", hoverText: "Especialista en realizar cirugías dentales complejas, incluyendo la colocación de implantes dentales para restaurar la funcionalidad y estética. Además, ofrece servicios de odontología general, abordando tratamientos comunes como empastes, limpiezas y revisiones periódicas." },
        { img: personal6, nombre: "Guillem Bernal" , especialidad: "Cirujano, implantólogo y generalista", hoverText: "Especialista en realizar cirugías dentales complejas, incluyendo la colocación de implantes dentales para restaurar la funcionalidad y estética. Además, ofrece servicios de odontología general, abordando tratamientos comunes como empastes, limpiezas y revisiones periódicas." },
        { img: personal7, nombre: "Mónica Queralt", especialidad: "Administradora de clínica", hoverText: "Apoya al equipo dental en tareas administrativas y asistenciales, asegurando un entorno organizado y eficiente para los pacientes y profesionales. Su rol incluye la preparación de instrumental, gestión de citas y atención al paciente durante su visita. Es fundamental en la coordinación de todas las actividades clínicas." },
        { img: personal1, nombre: "Paqui Sanchez", especialidad: "Administradora de clínica", hoverText: "Apoya al equipo dental en tareas administrativas y asistenciales, asegurando un entorno organizado y eficiente para los pacientes y profesionales. Su rol incluye la preparación de instrumental, gestión de citas y atención al paciente durante su visita. Es fundamental en la coordinación de todas las actividades clínicas." },
        { img: personal3, nombre: "Yaiza Álvarez", especialidad: "Higienista dental", hoverText: "Encargada de la prevención y tratamiento de enfermedades periodontales a través de limpiezas profundas, eliminación de placa y aplicación de tratamientos preventivos. Ofrece educación a los pacientes sobre el cuidado adecuado de sus dientes y encías, promoviendo una higiene bucal óptima." },
    ];

    useSEO({ title: 'Especialistas - Clínica Dental Dentsana', description: 'Conoce a nuestro equipo de especialistas altamente capacitados y apasionados por su trabajo. En Dentsana, ofrecemos soluciones innovadoras y efectivas para tu salud bucal.' });

    return (
        <div>
            <div className='head-aviso'>
                <Header />
            </div>
            <div className='especialistas-container'>
                <div className='titulo-especialistas'>
                    <div className='horizontal-titulo'></div>
                    <h1>ESPECIALISTAS</h1>
                    <div className='horizontal-titulo'></div>
                </div>
                <div className='contenedor-intros'>
                    <div className='intro-especialistas'>
                        <p>En Clínica Dental Dentsana, estamos convencidos de que la clave para alcanzar una salud bucal óptima y duradera reside en contar con un equipo especializado y altamente apasionado por su trabajo.</p>
                    </div>
                    <div className='vertical-space'></div>
                    <div className='intro-especialistas intro2'>
                        <p>Nuestro equipo de especialistas está compuesto por profesionales altamente capacitados y en constante actualización, enfocados en ofrecer soluciones innovadoras y efectivas para su salud bucal.</p>
                    </div>
                </div>
                <div className='grid-container'>
                    {especialistas.map((especialista, index) => (
                        <div key={index} className='grid-item'>
                            <img src={especialista.img} alt={especialista.nombre} />
                            <div className="overlay-text">{especialista.hoverText}</div>
                            <div className='info'>
                                <div className='nombre'>{especialista.nombre}</div>
                                <div className='especialidad'>{especialista.especialidad}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Especialistas;
