import React from 'react'
import './text.css';

function Text({text}) {
    return (
        <div className="text-container">
            <div className='vertical'></div>
            <p className='textp-style'>{text}</p>
        </div>
    )
}

export default Text