import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './nosotros.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import image1 from '../../assets/finalesfotos-06.png';
import image2 from '../../assets/finalesfotos-05.png';
import image3 from '../../assets/finalesfotos-07.png';
import image4 from '../../assets/top1.png';
import image5 from '../../assets/top2.png';
import image6 from '../../assets/top3.png';
import image7 from '../../assets/nosotros4.JPG';
import useSEO from '../../hooks/useSEO';

function Nosotros() {
  const [visibleTopImages, setVisibleTopImages] = useState([false, false, false]);
  const [visibleTexts, setVisibleTexts] = useState([false, false, false]);


  useEffect(() => {
    if (window.innerWidth <= 550) {
      setVisibleTexts([true, visibleTexts[1], visibleTexts[2]]);
    }
  }, []);

  const { ref: refTop1 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTopImages((prev) => [true, prev[1], prev[2]]);
    },
  });

  const { ref: refTop2 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTopImages((prev) => [prev[0], true, prev[2]]);
    },
  });

  const { ref: refTop3 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTopImages((prev) => [prev[0], prev[1], true]);
    },
  });

  const { ref: ref1 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTexts((prev) => [true, prev[1], prev[2]]);
    },
  });

  const { ref: ref2 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTexts((prev) => [prev[0], true, prev[2]]);
    },
  });

  const { ref: ref3 } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      if (inView) setVisibleTexts((prev) => [prev[0], prev[1], true]);
    },
  });

  useSEO({ title: 'Sobre Nosotros - Clínica Dental Dentsana', description: 'En Clínica Dental Dentsana contamos con mas de 20 años de experiencia y entendemos perfectamente que la salud bucal es una parte fundamental de tu bienestar general.' });

  return (
    <div>
      <div className='head-aviso'>
        <Header />
      </div>
      <div className='nosotros-container'>
        <div className='titulo-nosotros'>
          <div className='horizontal-titulo'></div>
          <h1>SOBRE NOSOTROS</h1>
          <div className='horizontal-titulo'></div>
        </div>
        
        <div className='images-container'>
          <img ref={refTop1} className={visibleTopImages[0] ? 'visible' : ''} src={image1} alt='Personal 1' />
          <img ref={refTop2} className={visibleTopImages[1] ? 'visible' : ''} src={image2} alt='Personal 2' />
          <img ref={refTop3} className={visibleTopImages[2] ? 'visible' : ''} src={image3} alt='Personal 3' />
        </div>

        <div className={`text-image-section ${visibleTexts[0] ? 'visible' : ''}`} ref={ref1}>
          <div className="text-content">
            <p>En Clínica Dental Dentsana contamos con mas de 20
              años de experiencia y entendemos perfectamente que la
              salud bucal es una parte fundamental de tu bienestar
              general. Como parte de nuestra familia, tu comodidad y
              confianza son nuestra prioridad. Nos enorgullece ofrecer
              un ambiente cálido y acogedor, donde cada miembro de
              tu familia, desde los más pequeños hasta los mayores,
              recibe el cuidado personalizado que merece. </p>
            <div className='hor-text'></div>
          </div>
          <img src={image4} alt='Personal 1' />
          <div className='hor-text2'></div>
        </div>

        <div className={`text-image-section ${visibleTexts[1] ? 'visible' : ''}`} ref={ref2}>
          <div className="text-content">
            <p>Nuestro equipo de profesionales, altamente
              capacitado y con una verdadera pasión por la
              odontología, está aquí para escucharte, comprender
              tus necesidades y ofrecerte soluciones que se
              adapten a tu estilo de vida. Ya sea que necesites un
              chequeo de rutina, un tratamiento especializado, o
              simplemente orientación sobre cómo mantener una
              sonrisa saludable, estamos contigo en cada paso del
              camino.</p>
            <div className='hor-text'></div>
          </div>
          <img src={image5} alt='Personal 2' />
          <div className='hor-text2'></div>
        </div>

        <div className={`text-image-section ${visibleTexts[2] ? 'visible' : ''}`} ref={ref3}>
          <div className="text-content">
            <p>En Clinica Dental Dentsana, no solo tratamos dientes,
              cuidamos personas. Nos esforzamos por crear
              relaciones duraderas basadas en la confianza y el
              respeto mutuo, para que cada visita al dentista sea
              una experiencia positiva y sin preocupaciones.</p>
            <div className='hor-text'></div>
          </div>
          <img src={image6} alt='Personal 3' />
          <div className='hor-text2'></div>
        </div>

      </div>
      <div className='promo-section'>
        <img src={image7} alt='Promotional' className='promo-image' />
        <div className='promo-text'>
          <h2>
            ¡Tu sonrisa es nuestra mejor tarjeta de presentación!<br /> Visítanos y descubre cómo podemos ayudarte a lucir una sonrisa sana y brillante, hoy y siempre!
          </h2>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Nosotros;
