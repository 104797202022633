import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Inicio from './pages/inicio/inicio';
import Privacidad from './pages/politicas/politicas-priv';
import Cookies from './pages/politicas/politicas-cookies';
import Aviso from './pages/politicas/aviso-legal';
import Nosotros from './pages/nosotros/nosotros';
import Servicios from './pages/servicios/servicios';
import Especialistas from './pages/especialistas/especialistas';
import Contacto from './pages/contacto/contacto';

function App() {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    console.log('Scroll to top triggered');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/sobre-nosotros" element={<Nosotros />} />
          <Route path="/politica-privacidad" element={<Privacidad />} />
          <Route path="/politica-cookies" element={<Cookies />} />
          <Route path="/aviso-legal" element={<Aviso />} />
          <Route path="/especialistas" element={<Especialistas />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/servicios/:section" element={<Servicios />} />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
        <button 
          onClick={scrollToTop} 
          className={`scrollToTopBtn ${showScroll ? 'show' : ''}`}
        >
          <img className='icon' src="https://api.iconify.design/ep:top.svg?color=white" alt='top'/>
        </button>
      </div>
    </Router>
  );
}

export default App;
