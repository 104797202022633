import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './servicios.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import ServicioText from '../../components/servicio-text/servicio-text';
import ImgOrtodonc from '../../assets/ortodoncia-s.png';
import ImgImplantes from '../../assets/implantes-s.png';
import ImgEstetica from '../../assets/estetica-s.png';
import ImgEndodoncia from '../../assets/caries-s.png';
import ImgCirugia from '../../assets/cirugia-s.png';
import ImgPeriodoncia from '../../assets/periodoncia-s.png';
import ImgProtesis from '../../assets/protesis-s.png';
import ImgPrevencion from '../../assets/prevencion-s.png';
import useSEO from '../../hooks/useSEO';

const ortodonciaContent = [
    { title: 'Convencional', text: 'utiliza brackets metálicos adheridos a los dientes y conectados por alambres. Este tratamiento corrige la alineación dental de manera efectiva, siendo una opción robusta y accesible para una amplia gama de problemas de mordida y alineación.' },
    { title: 'Invisalign', text: 'es un tratamiento de ortodoncia que utiliza alineadores transparentes removibles, hechos a medida para cada paciente. Estos alineadores son prácticamente invisibles, lo que los convierte en una opción popular para quienes buscan una solución estética y discreta sin el uso de brackets metálicos.' },
    { title: 'Ortodoncia Estética', text: 'similar a la ortodoncia convencional, pero utiliza brackets de materiales más discretos, como cerámica o zafiro, que se mimetizan con el color de los dientes. Es ideal para quienes desean los beneficios de la ortodoncia sin sacrificar la estética durante el tratamiento.' }
];

const ortodonciaContentShort = [
    { title: 'Convencional', text: 'utiliza brackets metálicos adheridos a los dientes y conectados por alambres. Este tratamiento corrige la alineación dental de manera efectiva, siendo una opción robusta y accesible.' },
    { title: 'Invisalign', text: 'es un tratamiento de ortodoncia que utiliza alineadores transparentes removibles, hechos a medida para cada paciente. ' },
    { title: 'Ortodoncia Estética', text: 'similar a la ortodoncia convencional, pero utiliza brackets de materiales más discretos, como cerámica o zafiro, que se mimetizan con el color de los dientes.' }
];

const implantesContent = [
    { title: 'Implantes Dentales Unitarios', text: 'son raíces artificiales de titanio que se colocan quirúrgicamente en el hueso maxilar o mandibular, sobre las cuales se fija una corona dental. Este tratamiento reemplaza un diente perdido de manera permanente, ofreciendo una solución que se siente y funciona como un diente natural.' },
    { title: 'Dentaduras Completas', text: 'son prótesis removibles que reemplazan todos los dientes de la mandíbula superior, inferior, o ambas. Están diseñadas para personas que han perdido todos sus dientes naturales, proporcionando una solución completa para restaurar la función y estética dental.' },
    { title: 'Puentes Dentales', text: 'son prótesis fijas que reemplazan uno o más dientes ausentes. Se anclan a los dientes adyacentes a la brecha mediante coronas y llenan el espacio con un diente artificial, conocido como póntico. Los puentes ayudan a mantener la alineación dental y la funcionalidad de la mordida.' }
];

const implantesContentShort = [
    { title: 'Implantes Dentales Unitarios', text: 'son raíces artificiales de titanio que se colocan quirúrgicamente en el hueso maxilar o mandibular, sobre las cuales se fija una corona dental.' },
    { title: 'Dentaduras Completas', text: 'son prótesis removibles que reemplazan todos los dientes de la mandíbula superior, inferior, o ambas. ' },
    { title: 'Puentes Dentales', text: 'son prótesis fijas que reemplazan uno o más dientes ausentes. Se anclan a los dientes adyacentes a la brecha mediante coronas y llenan el espacio con un diente artificial. ' }
];


const esteticaContent = [
    { title: 'Coronas Estéticas de Zirconio', text: 'son coronas dentales hechas de zirconio, un material altamente resistente y estético que imita el color y la translucidez natural de los dientes. Se utilizan para restaurar dientes dañados o debilitados, ofreciendo una solución duradera y visualmente atractiva.' },
    { title: 'Carillas Dentales', text: 'son finas láminas de porcelana o composite que se adhieren a la parte frontal de los dientes. Se utilizan para corregir imperfecciones estéticas como manchas, grietas, o dientes desalineados, logrando una sonrisa más uniforme y brillante.' },
    { title: 'Blanqueamiento Dental', text: 'es un tratamiento que aclara el color de los dientes, eliminando manchas y decoloraciones. Puede realizarse en la clínica o en casa con supervisión profesional, y es ideal para quienes desean una sonrisa más blanca y luminosa.' }
];

const esteticaContentShort = [
    { title: 'Coronas Estéticas de Zirconio', text: 'son coronas dentales hechas de zirconio, un material altamente resistente y estético que imita el color y la translucidez natural de los dientes.' },
    { title: 'Carillas Dentales', text: 'son finas láminas de porcelana o composite que se adhieren a la parte frontal de los dientes.' },
    { title: 'Blanqueamiento Dental', text: 'es un tratamiento que aclara el color de los dientes, eliminando manchas y decoloraciones.' }
];


const endodonciaContent = [
    { title: 'Empastes', text: 'son materiales que se utilizan para rellenar cavidades en los dientes causadas por caries. Los empastes restauran la estructura del diente, previenen futuras caries y restablecen su función normal. Pueden ser de amalgama, resina compuesta o porcelana, dependiendo de la ubicación y las necesidades estéticas.' },
    { title: 'Endodoncia', text: 'es un tratamiento también conocido como “tratamiento de conducto” o “desvitalización”, que se realiza cuando la pulpa del diente (el tejido blando en su interior) está infectada o dañada. Consiste en eliminar la pulpa afectada, limpiar y desinfectar el conducto radicular, y sellarlo para salvar el diente y evitar su extracción.' },
    { title: 'Reconstrucciones Dentales', text: 'se refiere a la restauración de un diente dañado, ya sea por caries, fracturas o desgaste, utilizando materiales como resina compuesta, amalgama o porcelana. Estas reconstrucciones pueden ser parciales o completas, dependiendo del grado de daño, y están diseñadas para restaurar la forma, función y apariencia del diente afectado.' }
];

const endodonciaContentShort = [
    { title: 'Empastes', text: 'son materiales que se utilizan para rellenar cavidades en los dientes causadas por caries. Los empastes restauran la estructura del diente, previenen futuras caries y restablecen su función normal.' },
    { title: 'Endodoncia', text: 'es un tratamiento también conocido como “tratamiento de conducto” o “desvitalización”, que se realiza cuando la pulpa del diente (el tejido blando en su interior) está infectada o dañada.' },
    { title: 'Reconstrucciones Dentales', text: 'se refiere a la restauración de un diente dañado, ya sea por caries, fracturas o desgaste, utilizando materiales como resina compuesta, amalgama o porcelana.' }
];

const cirugiaContent = [
    { title: 'Cirugía Bucal', text: 'es un procedimiento quirúrgico realizado en la boca, maxilares o estructuras relacionadas, con el fin de tratar diversas condiciones dentales y maxilofaciales. Esto puede incluir la extracción de muelas del juicio, la colocación de implantes dentales, corrección de deformidades faciales, tratamiento de quistes o tumores, y otros procedimientos necesarios para preservar la salud oral.<br/><br/> La cirugía bucal es generalmente realizada por un cirujano dental especializado y puede requerir anestesia local o general, dependiendo de la complejidad del caso.' }
];

const cirugiaContentShort = [
    { title: 'Cirugía Bucal', text: 'es un procedimiento quirúrgico realizado en la boca, maxilares o estructuras relacionadas, con el fin de tratar diversas condiciones dentales y maxilofaciales.<br/><br/> La cirugía bucal es generalmente realizada por un cirujano dental especializado y puede requerir anestesia local o general, dependiendo de la complejidad del caso.' }
];

const periodonciaContent = [
    { title: 'Periodoncia', text: 'es una rama de la odontología que se enfoca en la prevención, diagnóstico y tratamiento de enfermedades que afectan las encías y los tejidos de soporte de los dientes, como el hueso alveolar. Las condiciones más comunes tratadas por la periodoncia incluyen la gingivitis y la periodontitis, que pueden llevar a la pérdida de dientes si no se manejan adecuadamente. <br/><br/> El tratamiento puede incluir limpiezas profundas, cirugía de encías y otros procedimientos para restaurar la salud periodontal y prevenir futuras complicaciones.' }
];

const periodonciaContentShort = [
    { title: 'Periodoncia', text: 'es una rama de la odontología que se enfoca en la prevención, diagnóstico y tratamiento de enfermedades que afectan las encías y los tejidos de soporte de los dientes, como el hueso alveolar. <br/><br/> El tratamiento puede incluir limpiezas profundas, cirugía de encías y otros procedimientos para restaurar la salud periodontal y prevenir futuras complicaciones.' }
];

const protesisContent = [
    { title: 'Prótesis Dental', text: 'es un dispositivo diseñado para reemplazar dientes faltantes y restaurar tanto la función como la estética de la boca. Pueden ser removibles, como las dentaduras completas o parciales, o fijas, como los puentes o coronas sobre implantes. <br/><br/> Las prótesis dentales ayudan a mejorar la masticación, el habla y la apariencia, además de mantener la alineación de los dientes restantes y prevenir problemas en la mandíbula.' }
];

const protesisContentShort = [
    { title: 'Prótesis Dental', text: 'es un dispositivo diseñado para reemplazar dientes faltantes y restaurar tanto la función como la estética de la boca. <br/><br/> Las prótesis dentales ayudan a mejorar la masticación, el habla y la apariencia, además de mantener la alineación de los dientes restantes y prevenir problemas en la mandíbula.' }
];

const prevencionContent = [
    { title: 'Prevención Dental', text: 'consiste en prácticas y tratamientos diseñados para mantener una buena salud bucal y prevenir enfermedades dentales, como caries, gingivitis y periodontitis. Incluye hábitos como el cepillado y uso de hilo dental diario, visitas regulares al dentista para limpiezas y chequeos, la aplicación de flúor, sellantes dentales, y la educación sobre una dieta saludable. <br/><br/> La prevención dental es fundamental para evitar problemas mayores, reducir la necesidad de tratamientos complejos, y mantener una sonrisa saludable a lo largo del tiempo.' }
];

const prevencionContentShort = [
    { title: 'Prevención Dental', text: 'consiste en prácticas y tratamientos diseñados para mantener una buena salud bucal y prevenir enfermedades dentales, como caries, gingivitis y periodontitis. <br/><br/> La prevención dental es fundamental para evitar problemas mayores, reducir la necesidad de tratamientos complejos, y mantener una sonrisa saludable a lo largo del tiempo.' }
];

const services = [
    { id: 1, title: 'ORTODONCIA', content: ortodonciaContent, contentShort: ortodonciaContentShort, imageSrc: ImgOrtodonc },
    { id: 2, title: 'IMPLANTES DENTALES', content: implantesContent, contentShort: implantesContentShort, imageSrc: ImgImplantes },
    { id: 3, title: 'ESTÉTICA DENTAL', content: esteticaContent, contentShort: esteticaContentShort, imageSrc: ImgEstetica },
    { id: 4, title: 'ENDODONCIA', content: endodonciaContent, contentShort: endodonciaContentShort, imageSrc: ImgEndodoncia },
    { id: 5, title: 'CIRUGÍA BUCAL', content: cirugiaContent, contentShort: cirugiaContentShort, imageSrc: ImgCirugia },
    { id: 6, title: 'PERIODONCIA', content: periodonciaContent, contentShort: periodonciaContentShort, imageSrc: ImgPeriodoncia },
    { id: 7, title: 'PRÓTESIS DENTAL', content: protesisContent, contentShort: protesisContentShort, imageSrc: ImgProtesis },
    { id: 8, title: 'PREVENCIÓN DENTAL', content: prevencionContent, contentShort: prevencionContentShort, imageSrc: ImgPrevencion },
];

function Servicios() {
    const [activeService, setActiveService] = useState(null);
    const serviceRefs = useRef([]);
    const { section } = useParams();

    useEffect(() => {
        if (section) {
            const sectionId = Number(section);
            setActiveService(sectionId);
            const sectionRef = serviceRefs.current.find(ref => ref && ref.dataset.id == sectionId);
            if (sectionRef) {
                sectionRef.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            setActiveService(services[0].id);
        }
    }, [section]);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px 0px -20% 0px',
            threshold: 0.6,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const id = Number(entry.target.dataset.id);
                    setActiveService(id);
                }
            });
        }, observerOptions);

        serviceRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            serviceRefs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);

    useSEO({
        title: 'Servicios - Clínica Dental Dentsana',
        description: 'Implantología; Endodoncia; ATM. Pie de pagina. Dentsana ...'
    });

    return (
        <div>
            <div className='head-aviso'>
                <Header />
            </div>
            <div className='cont-serv'>
                <div className='titulo-servicios'>
                    <div className='horizontal-titulo'></div>
                    <h1>SERVICIOS</h1>
                    <div className='horizontal-titulo'></div>
                </div>
                <div>
                    {services.map((service, index) => (
                        <div
                            key={service.id}
                            ref={el => serviceRefs.current[index] = el}
                            data-id={service.id}
                            className={`servicio-text-container ${activeService === service.id ? 'service-active' : ''}`}
                        >
                            <ServicioText
                                title={service.title}
                                content={service.content}
                                contentShort={service.contentShort}
                                imageSrc={service.imageSrc}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='foot'>
                <Footer />
            </div>
        </div>
    );
}

export default Servicios;