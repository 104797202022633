import React from 'react';

const Mapa = () => {
    return (
        <div className="mapa-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5027.518593995167!2d1.906492263089851!3d41.47435116360366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a48ed24557fb21%3A0xd095bc840d15db2d!2sCl%C3%ADnica%20Dental%20Dentsana!5e0!3m2!1ses!2ses!4v1723572830771!5m2!1ses!2ses&z=17"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Ubicación de la clínica"
            ></iframe>
        </div>
    );
};

export default Mapa;
