import React from 'react';
import './inicio.css';
import clinicaImage from '../../assets/clinica.png';
import logo from '../../assets/logo.png';
import Experiencia from '../../components/experiencia/experiencia';
import Servicio from '../../components/servicio/servicio';
import Texto from '../../components/texto/text';
import Equipo from '../../components/equipo/equipo';
import DropdownMenu from '../../components/menu/menu';
import Footer from '../../components/footer/footer';
import Valoraciones from '../../components/valoraciones/valoraciones';
import Mapa from '../../components/mapa/mapa';
import ValoracionMov from '../../components/valoracion-movil/valoracion-mov';
import useSEO from '../../hooks/useSEO'; // Importa el hook


function Inicio() {
    const [activeLink, setActiveLink] = React.useState('#inicio');
    const [menuVisible, setMenuVisible] = React.useState(false);

    const handleSetActiveLink = (link) => {
        setActiveLink(link);
    };

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    };

    useSEO({
        title: 'Inicio - Clínica Dental Dentsana',
        description: 'Puede contactar con nosotros vía teléfono para pedir tu cita. Contacte ahora. Pie de pagina. Dentsana Martorell, S.L. © - Politica ...'
    });

    return (
        <div className="Inicio">
            <div className="imageContainer">
                <div className="rosaBarra"></div>
                <img className='imagenClinica' src={clinicaImage} alt="Clinica" />
                <div className="header">
                    <a href="/"><img src={logo} alt="Logo" /></a>
                    <div className='options'>
                        <ul className="menu-options">
                            <li><a className={activeLink === '#inicio' ? 'active' : ''} href="/" onClick={() => handleSetActiveLink('#inicio')}>INICIO</a></li>
                            <li><a className={activeLink === '#servicios' ? 'active' : ''} href="/servicios" onClick={() => handleSetActiveLink('#servicios')}>SERVICIOS</a></li>
                            <li><a className={activeLink === '#sobre-nosotros' ? 'active' : ''} href="/sobre-nosotros" onClick={() => handleSetActiveLink('#sobre-nosotros')}>SOBRE NOSOTROS</a></li>
                            <li><a className={activeLink === '#especialistas' ? 'active' : ''} href="/especialistas" onClick={() => handleSetActiveLink('#especialistas')}>ESPECIALISTAS</a></li>
                            <li><a className={activeLink === '#contacto' ? 'active' : ''} href="/contacto" onClick={() => handleSetActiveLink('#contacto')}>CONTACTO</a></li>
                        </ul>
                        <button className="menu-icon-button" onClick={toggleMenu}>
                            <img src="https://api.iconify.design/heroicons-solid:menu.svg?color=rgb(198, 25, 128)" alt="Menu" />
                        </button>
                    </div>
                </div>
                {menuVisible && <DropdownMenu onOptionClick={() => setMenuVisible(false)} />}
                <div className="texto-imagen">
                    <div className='first-line'>
                        <h2 className='not-bold-text'>SONRÍE</h2>
                        <h2 className='bold-text'>SIN COMPLEJOS</h2>
                    </div>
                    <div className='second-line'>
                        <h2 className='not-bold-text'>CON NOSOTROS</h2>
                    </div>
                    <div className='first-visit'>
                        <p>¡PRIMERA VISITA GRATUITA!</p>
                    <div className='phone-cont'>
                        <div className="phone-icon-circle">
                            <img src="https://api.iconify.design/clarity:phone-handset-solid.svg?color=%23ffffff" alt="Phone" />
                        </div>
                        <span className="phone-number">+34 937 754 624</span>
                    </div>
                    </div>
                </div>

            </div>
            <div className='textos-contenedor'>
                <Texto text='En Clinica Dental DentSana, estamos comprometidos con ofrecerte la mejor atención y los tratamientos dentales mas avanzados para que puedas lucir una sonrisa saludable y radiante.' />
                <Texto text='Nuestro equipo de profesionales altamente capacitados, con años de experiencia y formación continua, utiliza la tecnología de vanguardia más avanzada en el campo de la odontologia.' />
            </div>
            <div className='servicios-contenedor'>
                <Servicio value='Invisalign' />
                <Servicio value='Implantes' />
                <Servicio value='Empastes' />
                <Servicio value='Blanqueamiento' />
                <Servicio value='Cirugía Bucal' />
                <Servicio value='Prótesis Dental' />
            </div>
            <div className='expertise'>
                <Experiencia name="Años de experiéncia" value={20} velo={120} />
                <Experiencia name="Profesionales" value={7} velo={360} />
                <Experiencia name="Tratamientos" value={25} velo={90} />
            </div>
            <div className='equipo-cont'>
                <div className='titulo-val tit-eq'>
                    <div className='horizontal-2'></div>
                    <h1> Nuestro equipo</h1>
                    <div className='horizontal-2'></div>
                </div>
                <Equipo/>
            </div>
            <div className='valoraciones-cont'>
                <div className='titulo-val op-va-tit'>
                    <div className='horizontal-2'></div>
                    <h1 className='long-opinion'> Tu opinión es importante para nosotros</h1>
                    <h1 className="short-opinion">Tu opinión cuenta</h1>
                    <div className='horizontal-2'></div>
                </div>
                <div className="valoraciones">
                    <Valoraciones/>
                </div>
                <div className="valoracion-mov">
                    <ValoracionMov/>
                </div>
            </div>
        <div className='mapa-cont'>
            <div className='titulo-val'>
                    <div className='horizontal-2'></div>
                    <h1> Ubicación</h1>
                    <div className='horizontal-2'></div>
                </div>
            <Mapa/>
        </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default Inicio;
