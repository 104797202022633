import React, { useState, useEffect, useRef } from 'react'
import './experiencia.css'

const Experiencia = ({name, value, velo}) => {
    const [number, setNumber] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                setIsVisible(entry.isIntersecting);
            });
        });

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            const interval = setInterval(() => {
                setNumber(prevNumber => prevNumber < value ? prevNumber + 1 : prevNumber);
            }, velo);

            return () => {
                clearInterval(interval);
            };
        }
    }, [isVisible, value, velo]);

    return (
        <div className='exp-container' ref={ref}>
            <h2 className='exp-style'>{name}</h2>
            <div className='mas-text'>
            <h2>{name !== 'Profesionales' ? `+` : ''}</h2>
                <h2 className='value-style'>{number}</h2>
                </div>

        </div>
    )
}

export default Experiencia