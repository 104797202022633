import React from 'react';
import './aviso-legal.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import useSEO from '../../hooks/useSEO';

function Aviso() {

    useSEO({
        title: 'Aviso legal - Clínica Dental Dentsana',
        description: 'Denominación social: Dentsana Martorell, S.L.. NIF: B66192865. Domicilio: C/ Sant Esteve Sesrovires, 13 Local 2, 08760 – Martorell (Barcelona). Inscrita en el ...'
    });

    return (
        <div>
            <div className='head-aviso'>
                <Header />
            </div>
            <div className="text-aviso">
                <h1>Aviso Legal</h1>
                <p>En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE), el propietario de la web, le informa de lo siguiente:</p>

                <h2>Denominación social</h2>
                <p>Dentsana Martorell, S.L.</p>

                <h2>NIF</h2>
                <p>B66192865</p>

                <h2>Domicilio</h2>
                <p>C/ Sant Esteve Sesrovires, 13 Local 2, 08760 – Martorell (Barcelona)</p>

                <h2>Inscripción</h2>
                <p>Inscrita en el Registro Mercantil de Barcelona al Tomo 45786, folio 218, hoja número B498981, inscripción 1</p>

                <h2>Responsabilidad</h2>
                <p>Con los límites establecidos en la ley, Dentsana Martorell, S.L. no asume ninguna responsabilidad derivada de la falta de veracidad, integridad, actualización y precisión de los datos o informaciones que contienen sus páginas web.</p>

                <p>Los contenidos e información no vinculan a Dentsana Martorell, S.L. ni constituyen opiniones, consejos o asesoramiento legal de ningún tipo pues se trata meramente de un servicio ofrecido con carácter informativo y divulgativo.</p>

                <p>Las páginas de Internet de Dentsana Martorell, S.L. pueden contener enlaces (links) a otras páginas de terceras partes que Dentsana Martorell, S.L. no puede controlar. Por lo tanto, Dentsana Martorell, S.L. no puede asumir responsabilidades por el contenido que pueda aparecer en páginas de terceros.</p>

                <h2>Propiedad Intelectual e Industrial</h2>
                <p>La Web es una obra compuesta de diversos elementos integrados e inseparables (texto, ilustraciones, fotografías, imágenes animadas, vídeos, programas de ordenador, incluidos los códigos html del sitio web, etc.), cuya Propiedad Intelectual le corresponde a Dentsana Martorell, S.L., salvo en lo referente a aquellos materiales obtenidos bajo licencia de terceros.</p>

                <p>Dentsana Martorell, S.L. y sus licenciantes retienen en todo momento la Propiedad Intelectual sobre la Web y sobre los distintos elementos que la componen, individualmente considerados, en todas las copias que se realicen (cualquiera que sea el soporte al que se incorporen), concediendo sobre los mismos únicamente los derechos de uso que más adelante se describen. Cualquier derecho que no sea expresamente cedido se entiende reservado.</p>

                <p>Además de lo anterior, Dentsana Martorell, S.L. es responsable de la selección, diseño de la estructura y disposición de los contenidos de la Web, así como quien ha tomado la iniciativa y asumido el riesgo de efectuar las inversiones sustanciales orientadas a la obtención, digitalización y presentación de la misma, correspondiéndole, por tanto, la protección que el artículo 12 y el Título VIII del Libro II de la Ley de Propiedad Intelectual pueda conceder sobre el sitio web, considerado como una base de datos.</p>

                <p>Dentsana Martorell, S.L. es también el único dueño del diseño e imagen gráfica de la Web, reservándose las acciones legales pertinentes que le pudieran corresponder contra las personas que realicen imitaciones o usos desleales del mismo.</p>

                <h2>Contenidos Web y Descargas. Usos Permitidos y Prohibidos</h2>
                <p>Está permitida:</p>
                <ul>
                    <li>La navegación por la Web, es decir, el acceso y visualización de la misma en un dispositivo, quedando autorizada cualquier reproducción temporal o accesoria, siempre que la misma no sea voluntaria y forme parte integrante y esencial del proceso tecnológico de transmisión. La navegación por determinadas secciones de la Web requieren el previo registro.</li>
                    <li>Beneficiarse (previo registro) de los servicios y ventajas prestados por Dentsana Martorell, S.L. a través de la Web a sus usuarios, en las condiciones que se señalen expresamente en las distintas secciones.</li>
                </ul>
                <p>Queda terminantemente prohibido:</p>
                <ul>
                    <li>Cualesquiera operaciones respecto de la Web, sus contenidos, los productos descargados y las copias de todos ellos que sean contrarias a la Ley, las buenas costumbres y la buena fe.</li>
                    <li>Cualquier utilización fuera del ámbito personal y privado, especialmente aquellas que tengan fines comerciales o profesionales, incluido el envío de publicidad o mensajes y la recopilación y tratamiento de datos de terceros.</li>
                    <li>Cualquier tipo de extracción, comunicación pública y/o transmisión, total o parcial, por cualquier medio, fuera del ámbito privado de uso permitido y, especialmente, su incorporación a cualquier otra obra, incluidas páginas web, colecciones o bases de datos. Se exceptúa de esta prohibición la publicación en medios de comunicación de los materiales susceptibles de descarga de la sección Sala de Prensa.</li>
                    <li>La remoción, ocultación o falseamiento de los avisos y advertencias sobre la Propiedad Intelectual o Industrial de la Web o de cualquiera de los productos facilitados a través de la misma.</li>
                    <li>Las operaciones y actividades expresamente prohibidas en cualesquiera otras secciones de estas Condiciones Generales y, en general, cualquiera que pueda perjudicar el normal funcionamiento de la Web, a otros usuarios o a cualquier tercero.</li>
                </ul>

                <h2>Links a la Web</h2>
                <p>Queda autorizado el establecimiento de vínculos e hipervínculos con la Web desde otras páginas o sitios web, siempre que los mismos no se hagan de forma que perjudique la imagen pública y de marca de Dentsana Martorell, S.L., de la Web o de cualquiera de las personas y productos a que se haga referencia en la misma. En el establecimiento de vínculos con la Web queda expresamente prohibida la utilización de técnicas que impliquen confusión sobre la identidad y propiedad de los contenidos, tales como el framing u otras.</p>

                <p>Queda prohibido el establecimiento de vínculos desde páginas o sitios web cuyos contenidos promocionen o hagan apología, directa o indirectamente, de cualquier tipo de violencia, discriminación, pornografía o actividad ilegal. Asimismo, queda expresamente prohibido el establecimiento de links con fines mercantiles.</p>

                <p>En la creación de los vínculos queda expresamente prohibida la utilización de elementos extraídos de la Web, sin el consentimiento previo y expreso de Dentsana Martorell, S.L.</p>

                <p>En ningún caso podrá entenderse que los vínculos a la Web desde páginas o sitios web de terceros implican relaciones de Dentsana Martorell, S.L. con los titulares de éstos, ni implica respaldo, patrocinio o recomendación alguna de Dentsana Martorell, S.L. sobre los mismos, por lo que Dentsana Martorell, S.L. no será responsable en absoluto respecto de su contenido y licitud.</p>

                <h2>Contenidos y Comportamiento del Usuario</h2>
                <p>Como cliente o usuario de la Web, Ud. se compromete a hacer un uso adecuado de los contenidos y servicios ofrecidos a través de la misma y a no emplearlos para:</p>
                <ul>
                    <li>Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público.</li>
                    <li>Difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico, que haga apología del terrorismo o que atente contra los derechos humanos.</li>
                    <li>Provocar daños en los sistemas físicos y lógicos de Dentsana Martorell, S.L., de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados.</li>
                    <li>Difundir contenidos que atenten contra la imagen y reputación de Dentsana Martorell, S.L. o de terceros.</li>
                    <li>Atentar contra los derechos de Propiedad Intelectual, Industrial, de imagen, honor u otros que correspondan a Dentsana Martorell, S.L. o a terceros.</li>
                </ul>
                <p>Dentsana Martorell, S.L. tendrá plena libertad de decisión sobre si las colaboraciones y mensajes son finalmente publicadas en la Web o no, quedando facultada para retirarlos cuando estime oportuno.</p>

                <p>La infracción de cualquiera de las normas contenidas en estas Condiciones de uso y muy especialmente, de lo previsto en la presente cláusula, facultarán a Dentsana Martorell, S.L. para darle de baja de forma inmediata como usuario o suscriptor de la Web.</p>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default Aviso;
