import React from 'react';
import './personal.css';
import personal1 from '../../assets/personal-1.1.jpg';
import personal2 from '../../assets/personal-2.jpg';
import personal3 from '../../assets/personal-3.jpg';
import personal4 from '../../assets/personal-4.jpg';
import personal5 from '../../assets/personal-5.1.jpg';
import personal6 from '../../assets/personal-6.1.png';
import personal7 from '../../assets/personal-7.png';

const Personal = ({ value }) => {
    let personalImage = personal1;

    if (value === 'personal1') {
        personalImage = personal1;
    } else if (value === 'personal2') {
        personalImage = personal2;
    } else if (value === 'personal3') {
        personalImage = personal3;
    } else if (value === 'personal4') {
        personalImage = personal4;
    } else if (value === 'personal5') {
        personalImage = personal5;
    } else if (value === 'personal6') {
        personalImage = personal6;
    } else if (value === 'personal7') {
        personalImage = personal7;
    }

    return (
        <div className="personal-container">
            <img src={personalImage} alt={`Personal ${value}`} />
        </div>
    );
};

export default Personal;
