import React from 'react';
import { useNavigate } from 'react-router-dom';
import InvisalignImage from '../../assets/ortodoncia-s.png';
import ImplanteImage from '../../assets/implantes-s.png';
import EmpastesImage from '../../assets/caries-s.png';
import BlanqueamientoImage from '../../assets/estetica-s.png';
import ProtesisImage from '../../assets/periodoncia-s.png';
import CirugiaImage from '../../assets/cirugia-s.png';
import './servicio.css';

const Servicio = ({ value }) => {
    const navigate = useNavigate();

    let backgroundImage;
    let sectionId;

    switch (value) {
        case 'Invisalign':
            backgroundImage = `url(${InvisalignImage})`;
            sectionId = 1;
            break;
        case 'Empastes':
            backgroundImage = `url(${EmpastesImage})`;
            sectionId = 4;
            break;
        case 'Blanqueamiento':
            backgroundImage = `url(${BlanqueamientoImage})`;
            sectionId = 3;
            break;
        case 'Implantes':
            backgroundImage = `url(${ImplanteImage})`;
            sectionId = 2;
            break;
        case 'Cirugía Bucal':
            backgroundImage = `url(${CirugiaImage})`;
            sectionId = 5;
            break;
        case 'Prótesis Dental':
            backgroundImage = `url(${ProtesisImage})`;
            sectionId = 7;
            break;
        default:
            sectionId = null;
    }

    const handleVerMasClick = () => {
        if (sectionId !== null) {
            navigate(`/servicios/${sectionId}`);
        }
    };

    return (
        <div className='servicio-container' style={{ backgroundImage }}>
            <h2 className='serv-text-2'>{value}</h2>
            <button className='servicio-button' onClick={handleVerMasClick}>Ver más</button>
        </div>
    );
};

export default Servicio;
