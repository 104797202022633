import React from 'react';
import './politicas-priv.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import useSEO from '../../hooks/useSEO';

function Privacidad() {

    useSEO({
        title: 'Política de privacidad - Clínica Dental Dentsana',
        description: 'Dentsana Martorell, S.L. adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el ...'
    });


    return (
        <div className="privacidad">
            <div className='head-priv'>
                <Header />
            </div>
            <div className='text-priv'>
                <h1>Política de privacidad</h1>
                <p>Dentsana Martorell, S.L., en aplicación de la normativa vigente en materia de protección de datos de carácter personal, informa que los datos personales que se recogen a través de los formularios del Sitio web: <a href="https://www.dentsanamartorell.es">https://www.dentsanamartorell.es</a>, se incluyen en los ficheros automatizados específicos de usuarios de los servicios de Dentsana Martorell, S.L.</p>
                
                <p>La recogida y tratamiento automatizado de los datos de carácter personal tiene como finalidad el mantenimiento de la relación comercial y el desempeño de tareas de información, formación, asesoramiento y otras actividades propias de Dentsana Martorell, S.L.</p>

                <p>Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único objetivo de dar cumplimiento a la finalidad anteriormente expuesta.</p>

                <p>Dentsana Martorell, S.L. adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.</p>

                <p>El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición, rectificación, cancelación, limitación y portabilidad reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de email a: <a href="mailto:dentsanapm@gmail.com">dentsanapm@gmail.com</a> o en la dirección: C/ Sant Esteve Sesrovires, 13 Local 2, C.P. 08760 – Martorell (Barcelona).</p>

                <p>El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se compromete a mantenerlos actualizados, comunicando los cambios a Dentsana Martorell, S.L.</p>

                <h2>Finalidad del tratamiento de los datos personales:</h2>
                <p>¿Con qué finalidad trataremos tus datos personales?</p>
                <p>En Dentsana Martorell, S.L., trataremos tus datos personales recabados a través del Sitio Web: <a href="https://www.dentsanamartorell.es">https://www.dentsanamartorell.es</a>, con las siguientes finalidades:</p>
                <ul>
                    <li>Envío de información comercial y boletines sobre nuevos servicios ofrecidos en la web y del sector.</li>
                    <li>Enviar información promocional vía electrónica.</li>
                    <li>Facilitar la información solicitada por el usuario a través del formulario de contacto.</li>
                </ul>
                <p>Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier vía y en cualquier momento, remitiendo un correo electrónico a la dirección anteriormente indicada.</p>

                <p>Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible realizar las finalidades expresadas si no se aportan esos datos.</p>

                <h2>¿Por cuánto tiempo se conservan los datos personales recabados?</h2>
                <p>Los datos personales proporcionados se conservarán mientras se mantenga la relación comercial o no solicites su supresión y durante el plazo por el cuál pudieran derivarse responsabilidades legales por los servicios prestados.</p>

                <h2>Legitimación:</h2>
                <p>El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman el mismo:</p>
                <ul>
                    <li>La solicitud de información y/o la contratación de los servicios de Dentsana Martorell, S.L., cuyos términos y condiciones se pondrán a tu disposición en todo caso, de forma previa a una eventual contratación.</li>
                    <li>El consentimiento libre, específico, informado e inequívoco, en tanto que te informamos poniendo a tu disposición la presente política de privacidad, que tras la lectura de la misma, en caso de estar conforme, puedes aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto.</li>
                </ul>
                <p>En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no podremos atender tu solicitud, resultando del todo imposible proporcionarte la información solicitada o llevar a cabo la contratación de los servicios.</p>

                <h2>Destinatarios:</h2>
                <p>Los datos no se comunicarán a ningún tercero ajeno a Dentsana Martorell, S.L., salvo obligación legal.</p>

                <h2>Datos recopilados por usuarios de los servicios</h2>
                <p>En los casos en que el usuario incluya ficheros con datos de carácter personal en los servidores de alojamiento compartido, Dentsana Martorell, S.L. no se hace responsable del incumplimiento por parte del usuario del RGPD.</p>

                <h2>Retención de datos en conformidad a la LSSI</h2>
                <p>Dentsana Martorell, S.L. informa de que, como prestador de servicio de alojamiento de datos y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un periodo máximo de 12 meses la información imprescindible para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio. La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los requiera.</p>

                <p>La comunicación de datos a las Fuerzas y Cuerpos del Estado se hará en virtud a lo dispuesto en la normativa sobre protección de datos personales.</p>

                <h2>Derechos propiedad intelectual</h2>
                <p><a href="https://www.dentsanamartorell.es">https://www.dentsanamartorell.es</a></p>
                <p>Dentsana Martorell, S.L. es titular de todos los derechos de autor, propiedad intelectual, industrial, «know how» y cuantos otros derechos guardan relación con los contenidos del sitio web <a href="https://www.dentsanamartorell.es">https://www.dentsanamartorell.es</a> y los servicios ofertados en el mismo, así como de los programas necesarios para su implementación y la información relacionada.</p>

                <p>No se permite la reproducción, publicación y/o uso no estrictamente privado de los contenidos, totales o parciales, del sitio web <a href="https://www.dentsanamartorell.es">https://www.dentsanamartorell.es</a> sin el consentimiento previo y por escrito.</p>

                <h2>Propiedad intelectual del software</h2>
                <p>El usuario debe respetar los programas de terceros puestos a su disposición por Dentsana Martorell, S.L., aún siendo gratuitos y/o de disposición pública.</p>

                <p>Dentsana Martorell, S.L. dispone de los derechos de explotación y propiedad intelectual necesarios del software.</p>

                <p>El usuario no adquiere derecho alguno o licencia por el servicio contratado, sobre el software necesario para la prestación del servicio, ni tampoco sobre la información técnica de seguimiento del servicio, excepción hecha de los derechos y licencias necesarios para el cumplimiento de los servicios contratados y únicamente durante la duración de los mismos.</p>

                <p>Para toda actuación que exceda del cumplimiento del contrato, el usuario necesitará autorización por escrito por parte de Dentsana Martorell, S.L., quedando prohibido al usuario acceder, modificar, visualizar la configuración, estructura y ficheros de los servidores propiedad de Dentsana Martorell, S.L., asumiendo la responsabilidad civil y penal derivada de cualquier incidencia que se pudiera producir en los servidores y sistemas de seguridad como consecuencia directa de una actuación negligente o maliciosa por su parte.</p>

                <h2>Propiedad intelectual de los contenidos alojados</h2>
                <p>Se prohíbe el uso contrario a la legislación sobre propiedad intelectual de los servicios prestados por Dentsana Martorell, S.L. y, en particular de:</p>
                <ul>
                    <li>La utilización que resulte contraria a las leyes españolas o que infrinja los derechos de terceros.</li>
                    <li>La publicación o la transmisión de cualquier contenido que, a juicio de Dentsana Martorell, S.L., resulte violento, obsceno, abusivo, ilegal, racial, xenófobo o difamatorio.</li>
                    <li>La recopilación y/o uso de datos personales de otros usuarios sin su consentimiento expreso.</li>
                </ul>

                <h2>Exclusión de garantías y responsabilidad</h2>
                <p>Dentsana Martorell, S.L. no asume ninguna responsabilidad derivada, a título enunciativo pero no limitativo:</p>
                <ul>
                    <li>De la utilización que los usuarios hagan del sitio web o de los servicios y contenidos que se incluyen en el mismo.</li>
                    <li>De la existencia de virus u otros elementos que puedan causar daños en los sistemas, documentos electrónicos o ficheros de los usuarios.</li>
                    <li>De los daños y perjuicios que puedan derivarse del mal uso del sitio web.</li>
                    <li>De las posibles discrepancias que puedan surgir entre la versión de un documento impreso y la versión electrónica del mismo publicado en el sitio web.</li>
                </ul>

                <h2>Modificaciones</h2>
                <p>Dentsana Martorell, S.L. se reserva el derecho a modificar, en cualquier momento y sin necesidad de preaviso, la presentación y configuración del sitio web, así como la presente política de privacidad.</p>

                <p>Los usuarios aceptan y se someten a las condiciones de la política de privacidad que esté vigente en el momento en que accedan al sitio web.</p>

                <h2>Legislación aplicable y jurisdicción</h2>
                <p>La relación entre Dentsana Martorell, S.L. y el usuario se regirá por la normativa vigente en España y cualquier controversia se someterá a los juzgados y tribunales de la ciudad de Martorell (Barcelona).</p>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
}

export default Privacidad;
