import React from 'react';
import './valoracion.css';
import UserImage from '../../assets/user.png';
import TeethImage from '../../assets/star.png';

const Valoracion = ({ text, userName }) => {
    return (
        <div>
        <div className='valoracion-container'>
            <div className='valoracion-text'>
                <p>{text}</p>
                <div className='valoration-stars'>
                    <img src={TeethImage} alt="Star 1" />
                    <img src={TeethImage} alt="Star 2" />
                    <img src={TeethImage} alt="Star 3" />
                    <img src={TeethImage} alt="Star 4" />
                    <img src={TeethImage} alt="Star 5" />
                </div>
            </div>
        </div>
        <div className='valoracion-user'>
                <img src={UserImage} alt='Usuario' className='valoracion-user-image' />
                <p className='valoracion-user-name'>{userName}</p>
            </div>
        </div>
    );
};

export default Valoracion;
