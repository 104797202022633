import React from 'react';
import './contacto.css';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import ImageClinica from './../../assets/imagen-exterior.jpeg'
import useSEO from '../../hooks/useSEO';

const Contacto = () => {

    useSEO({ title: 'Contacto - Clínica Dental Dentsana', description: 'Ponte en contacto con nosotros para resolver tus dudas o pedir tu cita. En Dentsana, estamos comprometidos con tu salud bucal y bienestar.' });

    return (
        <div>
            <div className='head-aviso'>
                <Header />
            </div>

            <div className='titulo-contacto'>
                <div className='horizontal-titulo'></div>
                <h1>CONTACTO</h1>
                <div className='horizontal-titulo'></div>
            </div>
            <div className='cont-contacto'>
                <div className="contacto-info">
                    <div className="info-section">
                        <h3>
                            <img src="https://api.iconify.design/ph:phone.svg?color=rgb(198, 25, 128)"alt="Teléfono Icono" className="icono" />
                            Teléfono
                        </h3>
                        <p>+34 937 754 624</p>
                    </div>
                    <div className="info-section">
                        <h3>
                            <img src="https://api.iconify.design/ci:clock.svg?color=rgb(198, 25, 128)"alt="Horario Icono" className="icono" />
                            Horario
                        </h3>
                        <p><strong>Lunes:</strong> 10:00–13:30, 15:00–20:30</p>
                        <p><strong>Martes:</strong> 15:00–20:30</p>
                        <p><strong>Miércoles:</strong> 10:00–13:30, 15:00–20:30</p>
                        <p><strong>Jueves:</strong> 10:00–13:30, 15:00–20:30</p>
                        <p><strong>Viernes:</strong> 16:00–19:00</p>
                        <p><strong>Sábado:</strong> Cerrado</p>
                        <p><strong>Domingo:</strong> Cerrado</p>
                    </div>

                    <div className="info-section">
                        <h3>
                            <img src="https://api.iconify.design/ph:map-pin.svg?color=rgb(198, 25, 128)" alt="Dirección Icono" className="icono" />
                            Dirección
                        </h3>
                        <p>Carrer Sant Esteve Sesrovires, 13, local 2, 08760 Martorell, Barcelona</p>
                    </div>
                    <div className='redes-container'>
                    <div className='redes-cont'>
                        <img src="https://api.iconify.design/bxl:instagram.svg?color=rgb(198,25,128)" alt="" />
                        <h3>@dentsana_martorell</h3>
                    </div>
                    <div className='space-hor'></div>
                    <div className='redes-cont'>
                        <img src="https://api.iconify.design/bxl:facebook.svg?color=rgb(198,25,128)" alt="" />
                        <h3>Dentsana PM</h3>
                    </div>
                    </div>
                </div>
                <div>
                <div className="contacto-map">
                <div className='imagen-clinica'>
                        <img src={ImageClinica} alt="" />
                    </div>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5027.518593995167!2d1.906492263089851!3d41.47435116360366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a48ed24557fb21%3A0xd095bc840d15db2d!2sCl%C3%ADnica%20Dental%20Dentsana!5e0!3m2!1ses!2ses!4v1723572830771!5m2!1ses!2ses&z=17"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Ubicación de la clínica"
                    ></iframe>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Contacto;
